import React, { ElementRef } from 'react'
import { Button, ButtonProps } from '@sc/components/Button'
import { Overlay } from '@sc/components/Overlay'
import { CSS, keyframes, styled } from '@sc/theme/stitches.config'
import { Dialog } from '../../primitives/Dialog'

// TODO: Review animation with react-spring
const popIn = keyframes({
  '0%': { transform: 'translate(0,100%) scale(1)' },
  '100%': { transform: 'translate(0, 0) scale(1)' }
})

const ModalOverlay = styled(Dialog.Overlay, Overlay)

const ModalSection = styled('div', {
  paddingX: '$spacerYXS',
  paddingY: '$spacerYM',
  stack: '$spacerYM',

  '@sm': {
    padding: '$spacerYM'
  }
})

const StyledModalClose = styled('div', {
  display: 'none',
  // This button should only be showing for desktop
  '@sm': {
    display: 'block',
    position: 'absolute',
    boxShadow: '$levelTwo',
    backgroundColor: '$surfaceFg',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: '$L',
    padding: '$paddingUIS',
    top: 'calc(calc($sizes$controlHeight/2 + $paddingUIS)*-1)'
  }
})

const ModalClose = (props: ButtonProps) => (
  <StyledModalClose>
    <Button as={Dialog.Close} {...props} />
  </StyledModalClose>
)

export const modalStyles: CSS = {
  position: 'relative',
  gridColumn: '1/-1',
  outline: 'none',
  zIndex: '$modal',
  backgroundColor: '$surfaceFg',
  border: 'none',
  width: '100%',
  boxShadow: '$levelOne',
  pointerEvents: 'auto',
  height: 'min-content',
  borderRadius: '$M',
  '@sm': {
    gridColumn: '2/-2'
  },

  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${popIn} 400ms cubic-bezier(.24,.22,.22,1.04)`,
    willChange: 'transform'
  }
}

const BaseModal = styled(Dialog.Content, {
  ...modalStyles
})

type ModalRef = ElementRef<typeof Dialog.Content>

export type ModalPageProps = Dialog.DialogContentProps & {
  /**
   * Customise the overlay styling used for the page
   */
  overlayCSS?: CSS
  container?: Dialog.DialogPortalProps['container']
  css?: CSS
}

// TODO: revisit with latest changes on the Grid component
const overlayPageStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(12, 1fr)',
  overflow: 'scroll',
  paddingBottom: '$sizes$infoMinHeight',
  paddingTop: '$spacerYXS',

  '@sm': {
    padding: '$sizes$infoMinHeight'
  }
}

const ModalContent = React.forwardRef<ModalRef, ModalPageProps>(
  ({ children, container, overlayCSS, ...props }, forwardedRef) => {
    return (
      <Dialog.Portal container={container}>
        <ModalOverlay css={{ ...overlayPageStyle, ...overlayCSS }}>
          <BaseModal ref={forwardedRef} {...props}>
            {children}
          </BaseModal>
        </ModalOverlay>
      </Dialog.Portal>
    )
  }
)

export const ModalPage = {
  Root: Dialog.Root,
  Trigger: Dialog.Trigger,
  Content: ModalContent,
  Section: ModalSection,
  Close: ModalClose
}
